import React from 'react'
import { Link } from 'gatsby'

const Pagination = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext

  return (
    <nav className="pagination" role="navigation">
      {previousPagePath && (
        <div className="navbar-item">
          <Link to={previousPagePath} rel="prev">
            Vorige
          </Link>
        </div>
      )}
      {nextPagePath && (
        <div className="navbar-item">
          <Link to={nextPagePath} rel="next">
            Volgende
          </Link>
        </div>
      )}
    </nav>
  )
}

export default Pagination
