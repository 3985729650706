import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'

export default class AcademiePage extends React.Component {
  render() {
    const { data, pageContext, path } = this.props
    const { edges: posts } = data.allWpPost
    const { title } = data.site.siteMetadata
    const metaDesc =
      'De academie is een verzamelplek voor basiskennis en nieuws rond webdesign-webdevelopment. Contacteer ons voor nog meer kennis.'

    return (
      <Layout path={path}>
        <Helmet>
          <title>{`Academie | ${title}`}</title>
          <meta name="description" content={metaDesc} />
          <meta name="og:description" content={metaDesc} />
        </Helmet>
        <PostList posts={posts} arrowColor="#888" title="Academie" />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    )
  }
}

AcademiePage.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  query AcademieQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
  }
`
